import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./App.css";

function App() {
  const [state, setState] = useState({

    series: [
      {
        name: "Blood Pressure Range",
        type: "rangeBar",
        data: [
          { x: "01/09/2024 06:00", y: [120, 80] }, // Range from 80 to 120
          { x: "01/09/2024 12:00", y: [110, 60] },
          { x: "01/09/2024 18:00", y: [125, 80] },
          { x: "01/09/2024 24:00", y: [90, 80] },
          { x: "02/09/2024 06:00", y: [105, 80] },
          { x: "02/09/2024 12:00", y: [120, 80] },
          { x: "02/09/2024 18:00", y: [120, 80] },
          { x: "02/09/2024 24:00", y: [120, 80] },
          { x: "03/09/2024 06:00", y: [120, 80] },
          { x: "03/09/2024 12:00", y: [120, 80] },
          { x: "03/09/2024 18:00", y: [120, 80] },
          { x: "03/09/2024 24:00", y: [120, 80] },
          { x: "04/09/2024 06:00", y: [120, 80] },
          { x: "04/09/2024 12:00", y: [120, 80] },
          { x: "04/09/2024 18:00", y: [120, 80] },
          { x: "04/09/2024 24:00", y: [120, 80] },
          { x: "05/09/2024 06:00", y: [120, 80] },
          { x: "05/09/2024 12:00", y: [120, 80] },
          { x: "05/09/2024 18:00", y: [120, 80] },
          { x: "05/09/2024 24:00", y: [120, 80] },
          { x: "06/09/2024 06:00", y: [120, 80] },
        ],
      },
      {
        name: "Blood Sugar",
        type: "line",
        data: [
          { x: "01/09/2024 06:00", y: 110 },
          { x: "01/09/2024 12:00", y: 110 },
          { x: "01/09/2024 18:00", y: 110 },
          { x: "01/09/2024 24:00", y: 110 },
          { x: "02/09/2024 06:00", y: 110 },
          { x: "02/09/2024 12:00", y: 110 },
          { x: "02/09/2024 18:00", y: 110 },
          { x: "02/09/2024 24:00", y: 110 },
          { x: "03/09/2024 06:00", y: 110 },
          { x: "03/09/2024 12:00", y: 110 },
          { x: "03/09/2024 18:00", y: 110 },
          { x: "03/09/2024 24:00", y: 110 },
          { x: "04/09/2024 06:00", y: 110 },
          { x: "04/09/2024 12:00", y: 110 },
          { x: "04/09/2024 18:00", y: 110 },
          { x: "04/09/2024 24:00", y: 110 },
          { x: "05/09/2024 06:00", y: 110 },
          { x: "05/09/2024 12:00", y: 110 },
          { x: "05/09/2024 18:00", y: 110 },
          { x: "05/09/2024 24:00", y: 110 },
          { x: "06/09/2024 06:00", y: 110 },
        ],
      },
      {
        name: "Pulse",
        type: "scatter",
        data: [
          { x: "01/09/2024 06:00", y: 78 },
          { x: "01/09/2024 12:00", y: 75 },
          { x: "01/09/2024 18:00", y: 72 },
          { x: "01/09/2024 24:00", y: 68 },
          { x: "02/09/2024 06:00", y: 68 },
          { x: "02/09/2024 12:00", y: 69 },
          { x: "02/09/2024 18:00", y: 70 },
          { x: "02/09/2024 24:00", y: 72 },
          { x: "03/09/2024 06:00", y: 55 },
          { x: "03/09/2024 12:00", y: 60 },
          { x: "03/09/2024 18:00", y: 65 },
          { x: "03/09/2024 24:00", y: 72 },
          { x: "04/09/2024 06:00", y: 75 },
          { x: "04/09/2024 12:00", y: 77 },
          { x: "04/09/2024 18:00", y: 72 },
          { x: "04/09/2024 24:00", y: 55 },
          { x: "05/09/2024 06:00", y: 60 },
          { x: "05/09/2024 12:00", y: 65 },
          { x: "05/09/2024 18:00", y: 72 },
          { x: "05/09/2024 24:00", y: 75 },
          { x: "06/09/2024 06:00", y: 77 },
        ],
      },
      {
        name: "O2 SAT",
        type: "scatter",
        data: [
          { x: "01/09/2024 06:00", y: 98 },
          { x: "01/09/2024 12:00", y: 94 },
          { x: "01/09/2024 18:00", y: 92 },
          { x: "01/09/2024 24:00", y: 90 },
          { x: "02/09/2024 06:00", y: 88 },
          { x: "02/09/2024 12:00", y: 80 },
          { x: "02/09/2024 18:00", y: 95 },
          { x: "02/09/2024 24:00", y: 98 },
          { x: "03/09/2024 06:00", y: 92 },
          { x: "03/09/2024 12:00", y: 91 },
          { x: "03/09/2024 18:00", y: 98 },
          { x: "03/09/2024 24:00", y: 98 },
          { x: "04/09/2024 06:00", y: 93 },
          { x: "04/09/2024 12:00", y: 97 },
          { x: "04/09/2024 18:00", y: 98 },
          { x: "04/09/2024 24:00", y: 92 },
          { x: "05/09/2024 06:00", y: 91 },
          { x: "05/09/2024 12:00", y: 98 },
          { x: "05/09/2024 18:00", y: 98 },
          { x: "05/09/2024 24:00", y: 93 },
          { x: "06/09/2024 06:00", y: 97 },
        ],
      },
      {
        name: "Temperature",
        type: "scatter",
        data: [
          { x: "01/09/2024 06:00", y: 37.2 },
          { x: "01/09/2024 12:00", y: 36.5 },
          { x: "01/09/2024 18:00", y: 36.2 },
          { x: "01/09/2024 24:00", y: 36.8 },
          { x: "02/09/2024 06:00", y: 37.1 },
          { x: "02/09/2024 12:00", y: 38.2 },
          { x: "02/09/2024 18:00", y: 39.4 },
          { x: "02/09/2024 24:00", y: 40.1 },
          { x: "03/09/2024 06:00", y: 39.2 },
          { x: "03/09/2024 12:00", y: 38.7 },
          { x: "03/09/2024 18:00", y: 38.0 },
          { x: "03/09/2024 24:00", y: 37.9 },
          { x: "04/09/2024 06:00", y: 37.7 },
          { x: "04/09/2024 12:00", y: 37.5 },
          { x: "04/09/2024 18:00", y: 40.1 },
          { x: "04/09/2024 24:00", y: 39.2 },
          { x: "05/09/2024 06:00", y: 38.7 },
          { x: "05/09/2024 12:00", y: 38.0 },
          { x: "05/09/2024 18:00", y: 37.9 },
          { x: "05/09/2024 24:00", y: 37.7 },
          { x: "06/09/2024 06:00", y: 37.5 },
        ],
      },
      {
        name: "Resp. Rate",
        type: "line",
        data: [
          { x: "01/09/2024 06:00", y: 16 },
          { x: "01/09/2024 12:00", y: 16 },
          { x: "01/09/2024 18:00", y: 17 },
          { x: "01/09/2024 24:00", y: 18 },
          { x: "02/09/2024 06:00", y: 19 },
          { x: "02/09/2024 12:00", y: 12 },
          { x: "02/09/2024 18:00", y: 15 },
          { x: "02/09/2024 24:00", y: 18 },
          { x: "03/09/2024 06:00", y: 14 },
          { x: "03/09/2024 12:00", y: 16 },
          { x: "03/09/2024 18:00", y: 16 },
          { x: "03/09/2024 24:00", y: 17 },
          { x: "04/09/2024 06:00", y: 20 },
          { x: "04/09/2024 12:00", y: 19 },
          { x: "04/09/2024 18:00", y: 18 },
          { x: "04/09/2024 24:00", y: 14 },
          { x: "05/09/2024 06:00", y: 16 },
          { x: "05/09/2024 12:00", y: 16 },
          { x: "05/09/2024 18:00", y: 17 },
          { x: "05/09/2024 24:00", y: 20 },
          { x: "06/09/2024 06:00", y: 19 },
        ],
      },
      {
        name: "Pain Level",
        type: "line",
        data: [
          { x: "01/09/2024 06:00", y: 4 },
          { x: "01/09/2024 12:00", y: 4 },
          { x: "01/09/2024 18:00", y: 4 },
          { x: "01/09/2024 24:00", y: 4 },
          { x: "02/09/2024 06:00", y: 4 },
          { x: "02/09/2024 12:00", y: 4 },
          { x: "02/09/2024 18:00", y: 4 },
          { x: "02/09/2024 24:00", y: 4 },
          { x: "03/09/2024 06:00", y: 4 },
          { x: "03/09/2024 12:00", y: 4 },
          { x: "03/09/2024 18:00", y: 4 },
          { x: "03/09/2024 24:00", y: 4 },
          { x: "04/09/2024 06:00", y: 4 },
          { x: "04/09/2024 12:00", y: 4 },
          { x: "04/09/2024 18:00", y: 4 },
          { x: "04/09/2024 24:00", y: 4 },
          { x: "05/09/2024 06:00", y: 4 },
          { x: "05/09/2024 12:00", y: 4 },
          { x: "05/09/2024 18:00", y: 4 },
          { x: "05/09/2024 24:00", y: 4 },
          { x: "06/09/2024 06:00", y: 4 },
        ],
      },
      {
        name: "GCS",
        type: "line",
        data: [
          { x: "01/09/2024 06:00", y: 4 },
          { x: "01/09/2024 12:00", y: 5 },
          { x: "01/09/2024 18:00", y: 7 },
          { x: "01/09/2024 24:00", y: 2 },
          { x: "02/09/2024 06:00", y: 3 },
          { x: "02/09/2024 12:00", y: 5 },
          { x: "02/09/2024 18:00", y: 8 },
          { x: "02/09/2024 24:00", y: 9 },
          { x: "03/09/2024 06:00", y: 5 },
          { x: "03/09/2024 12:00", y: 5 },
          { x: "03/09/2024 18:00", y: 4 },
          { x: "03/09/2024 24:00", y: 6 },
          { x: "04/09/2024 06:00", y: 3 },
          { x: "04/09/2024 12:00", y: 7 },
          { x: "04/09/2024 18:00", y: 9 },
          { x: "04/09/2024 24:00", y: 5 },
          { x: "05/09/2024 06:00", y: 5 },
          { x: "05/09/2024 12:00", y: 4 },
          { x: "05/09/2024 18:00", y: 6 },
          { x: "05/09/2024 24:00", y: 3 },
          { x: "06/09/2024 06:00", y: 7 },
        ],
      },
      {
        name: "Triage",
        type: "line",
        data: [
          { x: "01/09/2024 06:00", y: 2 },
          { x: "01/09/2024 12:00", y: 2 },
          { x: "01/09/2024 18:00", y: 2 },
          { x: "01/09/2024 24:00", y: 2 },
          { x: "02/09/2024 06:00", y: 2 },
          { x: "02/09/2024 12:00", y: 2 },
          { x: "02/09/2024 18:00", y: 2 },
          { x: "02/09/2024 24:00", y: 2 },
          { x: "03/09/2024 06:00", y: 2 },
          { x: "03/09/2024 12:00", y: 2 },
          { x: "03/09/2024 18:00", y: 2 },
          { x: "03/09/2024 24:00", y: 2 },
          { x: "04/09/2024 06:00", y: 2 },
          { x: "04/09/2024 12:00", y: 2 },
          { x: "04/09/2024 18:00", y: 2 },
          { x: "04/09/2024 24:00", y: 2 },
          { x: "05/09/2024 06:00", y: 2 },
          { x: "05/09/2024 12:00", y: 2 },
          { x: "05/09/2024 18:00", y: 3 },
          { x: "05/09/2024 24:00", y: 3 },
          { x: "06/09/2024 06:00", y: 2 },
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "rangeBar",
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [0, 2, 2, 2, 2, 2, 2, 2, 2], // No stroke for rangeBar, 2 for the line
        curve: "smooth",
      },
      markers: {
        size: [0, 0, 5, 5, 5, 5, 5, 5, 0], // No markers for rangeBar, larger markers for line
      },
      title: {
        text: "Monitoring",
        align: "left",
      },
      xaxis: {
        // categories: [" 10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00"]
        type: "category",
        labels: {
          formatter: function (val) {
            let time = new Date(val);
            let tt = time.getHours()
            let mm = time.getMinutes()
            let t =("0" + tt).slice(-2) +":"+ ("0" + mm).slice(-2);
            return t;
          },
        },
        
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
        groups: [
          { title: "01/09/2024", cols: 4 },
          { title: "02/09/2024", cols: 4 },
          { title: "03/09/2024", cols: 4 },
          { title: "04/09/2024", cols: 4 },
          { title: "05/09/2024", cols: 4 },
          { title: "06/09/2024", cols: 1 },
        ],
        },

      },
      yaxis: [
        {
          title: {
            text: ''
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            style: {
              colors: '#008FFB'
            },
          },
          min: 0,
          max: 150,
          seriesName: [
            "Blood Pressure Range",
            "Blood Sugar",
            "Pulse",
            "O2 SAT",
          ],
        },
        {
          opposite: true,
          title: {
            text: "",
          },
           axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#00E396'
          },
          labels: {
            style: {
              colors: '#00E396'
            }
          },
          min: 0,
          max: 45,
          seriesName: [
            "Temperature",
            "Resp. Rate",
            "Pain Level",
            "GCS",
            "Triage",
          ],
        },
      ],
      tooltip: {
        shared: false,
        intersect: false
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    },
  
  });

  return (
    <div className="App">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="rangeBar"
        height={500}
      />
    </div>
  );
}

export default App;
